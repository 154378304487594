# requires classList.js
import 'javascripts/_ui/ui.menu_link';
import 'javascripts/_ui/ui.form_validator';

window.UI ||= {}
class UI.Manager
  'use strict'

  menuLink: ->
    return @_menuLink if @_menuLink?
    @_menuLink = new UI.MenuLink('menu_link')
    @_menuLink.init()
    @_menuLink

  addCollapsibles: ->
    $('.js_collapsible').each (i, elem) ->
      $(elem).on 'click', (e) ->
        $(e.target).addClass('js_collapsed')

  addProgressiveClasses: ->
    $('[data-add-class]').each (i, elem) ->
      $(elem).addClass($(elem).attr('data-add-class'))


  addFormValidation: ->
    return @_afv if @_afv?
    @_afv = new UI.FormValidator()
    @_afv.init()
    @_afv

  # addResizeHandler: =>
  #   window.addEventListener 'resize', window.debounce( () =>
  #     console.log 'resize'
  #     # if document.body.clientWidth < 600
  #     #   @homepageLogos().unload()

  #   , 300)

  init: =>
    # Phase 1, general classes
    @addProgressiveClasses()
    @menuLink()

    # Phase 2, resolution-independent classes
    @addCollapsibles()
    @addFormValidation()

    # Phase 3, resolution-specific classes
    # if document.body.clientWidth < 800
    #   @workpageCarousel().init()

    # Phase 4, Watch the window.resize event
    #@addResizeHandler()

nztaUI = new UI.Manager()
nztaUI.init()
