import 'javascripts/_lib/jquery.validate.min';

window.UI ||= {}
class UI.FormValidator
	'use strict'

	addFormValidation: =>
		$('form').each (i, form) ->
			$(form).validate({
				errorElement: 'em'
				errorClass: 'field_helper field_helper_error'
				highlight: (element, errorClass, validClass) ->
					$(element).closest('.form_row').addClass('form_row_error')
					$(element).closest('.form_row').find('em.error').addClass('field_helper field_helper_error')
				unhighlight: (element, errorClass, validClass) ->
					$(element).closest('.form_row').removeClass('form_row_error')
					$(element).closest('.form_row').find('em.error').remove()
			})

	init: =>
		@addFormValidation()
