window.UI ||= {}
class UI.MenuLink
  'use strict'

  constructor: (elemID) ->
    @menuLink = $('#' + elemID)

  menuLink: null

  addMenuClick: =>
    @menuLink.on 'click', (e) ->
      e.preventDefault()
      if $(e.target).attr('id') == 'menu_link'
        $('body').toggleClass('menu_closed')
        $('body').addClass('admin_menu_closed')
      else
        $('body').toggleClass('admin_menu_closed')
        $('body').addClass('menu_closed')

  init: =>
    @addMenuClick() if @menuLink
